import Vue from "vue";
import Router from "vue-router";
import layout from "@/Layout";

Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export const constantRoutes = [
  {
    path: "/",
    component: layout,
    children: [
      {
        path: "/",
        name: "index1",
        component: () => import("@/views/home/index"),
        meta: {
          title: "首页",
        },
      },
    ],
  },
  {
    path: "/llcHome",
    component: layout,
    children: [
      {
        path: "/",
        name: "llcHome",
        component: () => import("@/views/LhcHome/index"),
        meta: {
          title: "首页",
        },
      },
    ],
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/components/home"),
    meta: {
      title: "智慧政务",
    },
  },
  {
    path: "/meetdispatch",
    component: layout,
    children: [
      {
        path: "/meetdispatch",
        name: "meetdispatch",
        component: () => import("@/views/components/meetdispatch"),
        meta: {
          title: "应急指挥调度",
        },
      },
    ],
  },
  {
    path: "/opinionsurvey",
    component: layout,
    children: [
      {
        path: "/opinionsurvey",
        name: "opinionsurvey",
        component: () => import("@/views/components/opinionsurvey"),
        meta: {
          title: "民意调查",
        },
      },
    ],
  },
  {
    path: "/pomegranate",
    component: layout,
    children: [
      {
        path: "/pomegranate",
        name: "pomegranate",
        component: () => import("@/views/components/pomegranate"),
        meta: {
          title: "矛盾纠纷调解",
        },
      },
    ],
  },
  {
    path: "/publication",
    component: layout,
    children: [
      {
        path: "/publication",
        name: "publication",
        component: () => import("@/views/components/publication"),
        meta: {
          title: "民意调查",
        },
      },
    ],
  },
  {
    path: "/specialpersonnel",
    component: layout,
    children: [
      {
        path: "/specialpersonnel",
        name: "specialpersonnel",
        component: () => import("@/views/components/specialpersonnel"),
        meta: {
          title: "特殊人员管理",
        },
      },
    ],
  },
  {
    path: "/specialpersonnelLhc",
    component: layout,
    children: [
      {
        path: "/specialpersonnelLhc",
        name: "specialpersonnelLhc",
        component: () => import("@/views/components/specialpersonnelLhc"),
        meta: {
          title: "特殊人员管理",
        },
      },
    ],
  },
  {
    path: "/treevideo",
    component: layout,
    children: [
      {
        path: "/treevideo",
        name: "treevideo",
        component: () => import("@/views/treevideo/treevideo"),
        // component: () => import("@/views/treevideo/XgTreeVideo"),
        meta: {
          title: "监控中心",
        },
      },
    ],
  },
  {
    path: "/jbfishery",
    component: layout,
    children: [
      {
        path: "/jbfishery",
        name: "jbfishery",
        component: () => import("@/views/jbfishery/jbfishery"),
        meta: {
          title: "江北渔业",
        },
      },
    ],
  },
  {
    path: "/instashot",
    name: "instashot",
    component: () => import("@/views/instashot/index"),
    meta: {
      title: "随手拍",
    },
  },
  {
    path: "/searchImage",
    component: layout,
    children: [
      {
        path: "/searchImage",
        name: "searchImage",
        component: () => import("@/views/searchImage/searchImage"),
        meta: {
          title: "以图搜图",
        },
      },
    ],
  },
  {
    path: "/aiAlerts",
    component: layout,
    children: [
      {
        path: "/aiAlerts",
        name: "aiAlerts",
        component: () => import("@/views/aiAlerts/aiAlerts"),
        meta: {
          title: "AI告警中心",
        },
      },
    ],
  },
  {
    path: "/newaiAlerts",
    name: "newaiAlerts",
    component: () => import("@/views/aiAlerts/newAiAlerts.vue"),
    meta: {
      title: "AI告警中心",
    },
  },
  {
    path: "/Hikangvideo",
    component: layout,
    children: [
      {
        path: "/Hikangvideo",
        name: "Hikangvideo",
        component: () => import("@/views/Hikangvideo/Hikangvideo"),
        meta: {
          title: "海康监控",
        },
      },
    ],
  },
  {
    path: "/frontconstruct",
    component: layout,
    children: [
      {
        path: "/frontconstruct",
        name: "frontconstruct",
        component: () => import("@/views/frontconstruct/frontconstruct"),
        meta: {
          title: "阵地建设",
        },
      },
    ],
  },
  {
    path: "/zdPositionConst",
    component: layout,
    children: [
      {
        path: "/zdPositionConst",
        name: "zdPositionConst",
        component: () => import("@/views/ZhangDe/zdPositionConst"),
        meta: {
          title: "阵地建设",
        },
      },
    ],
  },
  {
    path: "/zdemergencies",
    component: layout,
    children: [
      {
        path: "/zdemergencies",
        name: "zdemergencies",
        component: () => import("@/views/ZhangDe/NewZdemergencies"),
        meta: {
          title: "应急指挥调度",
        },
      },
    ],
  },
  {
    path: "/CommandSystem",
    component: layout,
    children: [
      {
        path: "/",
        name: "CommandSystem",
        component: () => import("@/views/CommandSystem/index.vue"),
        meta: {
          title: "应急指挥体系",
        },
      },
    ],
  },
  {
    path: "/ComSystemDetails",
    component: layout,
    children: [
      {
        path: "/",
        name: "ComSystemDetails",
        component: () => import("@/views/CommandSystem/details.vue"),
        meta: {
          title: "应急指挥体系",
        },
      },
    ],
  },
  {
    path: "/SafetyInspection",
    component: layout,
    children: [
      {
        path: "/",
        name: "SafetyInspection",
        component: () => import("@/views/SafetyInspection/index.vue"),
        meta: {
          title: "安全巡查",
        },
      },
    ],
  },
  {
    path: "/newCenter",
    component: layout,
    children: [
      {
        path: "/newCenter",
        name: "newCenter",
        component: () => import("@/views/newCenter/index"),
        meta: {
          title: "阵地建设",
        },
      },
    ],
  },
  {
    path: "/PingAnVideoLink",
    component: layout,
    children: [
      {
        path: "/",
        name: "PingAnVideoLink",
        component: () => import("@/views/PingAnVideoLink/index"),
        meta: {
          title: "平安视联",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login"),
    meta: {
      title: "基层治理数字大屏",
    },
  },
  {
    path: "/amapComm",
    name: "amapComm",
    component: () => import("@/components/amapComm/index.vue"),
    meta: {
      title: "amapComm",
    },
  },
  {
    path: "/iframeMeetMap",
    name: "iframeMeetMap",
    component: () => import("@/components/MeetMap/index.vue"),
    meta: {
      title: "amapComm",
    },
  },
  {
    path: "/404",
    component: () => import("@/views/error/404"),
  },
  {
    path: "/401",
    component: () => import("@/views/error/401"),
  },
  {
    path: "*",
    component: () => import("@/views/error/404"),
  },
];

const router = new Router({
  mode: "history", // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

router.beforeEach((to, from, next) => {
  const istoken = localStorage.getItem("token");
  if (!istoken) {
    if (to.path !== "/login" && !istoken) {
      document.title = "基层治理数字大屏";
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
