<style lang="less" scoped>
.mintitle-box-bg {
  position: relative;
  height: 24px;
  background-image: url("@/assets/comimg/mintitle-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 6px;

  .mintitle-text {
    position: absolute;
    color: #23d7f5;
    font-size: 20px;
    top: -10px;
    padding-left: 18px;
  }
}
</style>
<template>
  <div class="mintitle-box-bg" v-on="$listeners" :style="{backgroundImage: `url(${require( '@/assets/comimg/'+ titlebg )})`}">
    <div class="mintitle-text" :style="{color:titletextcolor}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "mintitle",
  props: {
    titlebg: {
      type: String,
      default: "mintitle-bg.png",
    },
    titletextcolor: {
      type: String,
      default: "#23D7F5",
    },
  },
};
</script>
