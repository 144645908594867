import request from "./request";

// 登录方法
export function login(data) {
  return request({
    url: "/api/v1/login",
    // url: '/vue/index/login',
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}

// 获取菜单列表内容
export function getrouters(data) {
  return request({
    url: "/api/v1/routers",
    method: "get",
    params: data,
  });
}

// 菜单获取-列表首页
export function getrouterslist(data) {
  return request({
    url: "/api/v1/routers/list",
    method: "get",
    params: data,
  });
}

// 矛盾纠纷调解
export function getData(data) {
  return request({
    url: "/api/v1/mediation/statistics",
    method: "get",
    data: data,
  });
}

// 民意调查
export function getquestData(data) {
  return request({
    url: "/api/v1/survey/statistics",
    method: "get",
    data: data,
  });
}

// 特殊人员
export function getspecial(data) {
  return request({
    url: "/api/v1/special/statistics",
    method: "get",
    data: data,
  });
}

// 特殊人员类型列表
export function getspecialList(data) {
  return request({
    url: `/api/v1/special/list`,
    method: "get",
    params: data,
  });
}

// 首页静态统计
export function getvqindex(data) {
  return request({
    url: "/api/v2/index",
    method: "get",
    data: data,
  });
}

// 新版首页数据接口
export function getv1home(data) {
  return request({
    url: "/api/v1/home",
    method: "get",
    params: data,
  });
}

// 新版首页地图数据接口
export function getVillageInfo(data) {
  return request({
    url: "/api/v1/index/village/info",
    method: "get",
    params: data,
  });
}

// 新版首页告警数量
export function getalarmcount(data) {
  return request({
    url: "/api/v1/alarm/count",
    method: "get",
    data: data,
  });
}

// 首页党委人员查询
export function getpersons(data) {
  return request({
    url: "/api/v1/party/persons",
    method: "get",
    params: data,
  });
}

// 首页党建活动分页查询
export function getactivities(data) {
  return request({
    url: "/api/v1/party/activities",
    method: "get",
    params: data,
  });
}

// 智慧政务
export function getsmartparty(data) {
  return request({
    url: "/api/v1/smart/party",
    method: "get",
    params: data,
  });
}

// 阵地建设
export function getsmartposition(data) {
  return request({
    url: "/api/v2/smart/position",
    method: "get",
    params: data,
  });
}

// 阵地建设详情
export function getsmartpositioninfo(data) {
  return request({
    url: "/api/v2/smart/position/info",
    method: "get",
    params: data,
  });
}

// 组织信息
export function saasinfo(data) {
  return request({
    url: "/api/v1/organ/info",
    method: "get",
    params: data,
  });
}

// 组织配置修改
export function organconfig(data) {
  return request({
    url: "/api/v1/organ/config",
    method: "post",
    data: data,
  });
}

// 天气预报
export function getquery(data) {
  return request({
    url: "/api/v1/weather/query",
    method: "get",
    params: data,
  });
}

// 应急指挥调度-告警信息查询
export function getnotice(data) {
  return request({
    url: "/api/v1/urgent/notice",
    method: "get",
    params: data,
  });
}

// 应急指挥调度
export function getstatistics(data) {
  return request({
    url: "/api/v1/urgent/statistics",
    method: "get",
    params: data,
  });
}

// 获取平安视联参数
export function getviewtoken() {
  return request({
    url: "/api/safe/view/token",
    method: "get",
  });
}

// 应急指挥调度-详情 --公共
export function getstatilist(data) {
  return request({
    url: "/api/v1/urgent/list",
    method: "get",
    params: data,
  });
}

// 应急指挥调度-摄像头详情
export function getcamera(data) {
  return request({
    url: "/api/v1/urgent/camera",
    method: "get",
    params: data,
  });
}

// 应急指挥调度-物资详情
export function getgoods(data) {
  return request({
    url: "/api/v1/urgent/goods",
    method: "get",
    params: data,
  });
}

// 首页-阵地建设-列表
export function getpositionlist(data) {
  return request({
    url: "/api/v1/position/list",
    method: "get",
    params: data,
  });
}

// 首页-阵地建设-详情
export function getpositioninfo(data) {
  return request({
    url: "/api/v1/position/info",
    method: "get",
    params: data,
  });
}

// 首页-摄像头信息
export function getposcamera(data) {
  return request({
    url: "/api/v1/index/camera",
    method: "get",
    params: data,
  });
}

// 首页-获取社区坐标
export function getOptionCommunity(data) {
  return request({
    url: "/api/v1/index",
    method: "get",
    params: data,
  });
}

// 监控中心-左侧树节点
export function getcamerasdep(data) {
  return request({
    url: "/api/v1/cameras/dep",
    method: "get",
    params: data,
  });
}

// 监控中心-中间九宫格
export function getcamerasplay(data) {
  return request({
    url: "/api/v1/cameras/play",
    method: "get",
    params: data,
  });
}

// 监控中心-右侧告警事件
export function getcamerasevent(data) {
  return request({
    url: "/api/v1/cameras/event",
    method: "get",
    params: data,
  });
}

// 江北渔业-园区列表
export function getparksfishery(data) {
  return request({
    url: "/api/v1/fishery/parks",
    method: "get",
    params: data,
  });
}

// 江北渔业-数据统计
export function getfisherystatistics(data) {
  return request({
    url: "/api/v1/fishery/statistics",
    method: "get",
    params: data,
  });
}

// 应急指挥调度-摄像头详情V2
export function geturgentcamera(data) {
  return request({
    url: "/api/v2/urgent/camera",
    method: "get",
    params: data,
  });
}

// 应急指挥调度-摄像头直播地址
export function getcameragetLiveUrl(data) {
  return request({
    url: "/api/v2/urgent/camera/getLiveUrl",
    method: "get",
    params: data,
  });
}

// 海康摄像头列表
export function hkcameralist(data) {
  return request({
    url: "/api/v1/hk/camera/list",
    method: "get",
    params: data,
  });
}

// 海康摄像头播放流
export function cameraplayUrl(data) {
  return request({
    url: "/api/v1/hk/camera/playUrl",
    method: "get",
    params: data,
  });
}

// 调整摄像头位置
// /** 设备编码 */
// private String deviceCode;
// /** 操控指令，0=停止，1=上，2=下，3=左，4=右，5=左上，6=左下，7=右上，8=右下  */
// private Integer controlPowerCode;
// 接口：/controller/control
export function controllercontrol(data) {
  return request({
    url: "/controller/control",
    method: "post",
    data: data,
  });
}

// 根据编码控制摄像头转向
export function camerasetpt(data) {
  return request({
    url: "/api/v1/camera/setpt",
    method: "post",
    data: data,
  });
}

// 根据编码控制摄像头巡航
export function cameracontrol(data) {
  return request({
    url: "/api/v1/camera/control",
    method: "post",
    data: data,
  });
}

//新闻列表
export function newList(data) {
  return request({
    url: "/api/person/activity/list",
    method: "get",
    params: data,
  });
}

//新闻详情
export function newInfo(data) {
  return request({
    url: "/api/person/activity/" + data,
    method: "get",
    params: {},
  });
}

//网格人员列表
export function mapPeopleList(data) {
  return request({
    url: "/api/position/grid/person",
    method: "get",
    params: data,
  });
}

// 案件详情
export function getCase(data) {
  return request({
    url: "/case/get", //?id= 405
    method: "get",
    params: data,
  });
}

export function getGridTree(data) {
  return request({
    url: "/api/emergency/grid/tree", //?id= 405
    method: "get",
    params: data,
  });
}

//通过code获取视频
export function getVideoForCode(data) {
  return request({
    url: "/api/v1/camera/code", //?id= 405
    method: "get",
    params: data,
  });
}

//通过code获取视频
export function speakVideo(data) {
  return request({
    url: "/api/v1/camera/shout/start", //?id= 405
    method: "post",
    data: data,
  });
}

//急指挥体系
export function urgentplan(data) {
  return request({
    url: "/api/urgent/plan", //?id= 405
    method: "get",
    params: data,
  });
}

//急指挥体系运行图
export function urgentgroup(data) {
  return request({
    url: "/api/urgent/group", //?id= 405
    method: "get",
    params: data,
  });
}

