const user = {
    state: {
        userinfo: {},
        mapstate: 'meetdispatch',
    },
    mutations: {
        SET_USERINFO: (state, userinfo) => {
            state.userinfo = userinfo
        },
        SET_MapState: (state, mapstate) => {
            state.mapstate = mapstate
        }
    }
}

export default user