<template>
  <div id="contApp">
    <!--    <div id="container">-->
<!--     <ScaleBox :width="1920" :height="1080" bgc="transparent" :delay="1" :isFlat="true">-->
      <router-view/>
<!--     </ScaleBox>-->
<!--        </div>-->
  </div>
</template>

<script>
import VScaleScreen from 'v-scale-screen';
import ScaleBox from 'vue2-scale-box';
import autofit from 'autofit.js';

export default {
  name: 'App',
  components: {VScaleScreen, ScaleBox},
  data() {
    return {};
  },
  created() {
  },
  mounted() {
    // autofit.init({
    //   dh: 1080,
    //   dw: 1920,
    //   el: "#container",
    //   resize: true,
    //   ignore: [{
    //     el: "#amapComm"
    //   }]
    // }, false) // 可关闭控制台运行提示输出
    // this.transformfun();
    // window.addEventListener('resize', this.transformfun);
  },
  methods: {
    transformfun() {
      setTimeout(() => {
        if (window.self !== window.top) {
          let _element = document.getElementById('container');
          _element.style.width = '100%';
          _element.style.height = '100%';
          _element.style.position = 'absolute';
          _element.style.left = '0%';
          _element.style.top = '0%';
          _element.style.marginLeft = 'inherit';
          _element.style.marginTop = 'inherit';
          _element.style.overflow = 'inherit';
          return false;
        }
        let element = document.getElementById('container');
        // 1.重置container大小
        var width = window.innerWidth || document.documentElement.clientWidth;
        var height = window.innerHeight || document.documentElement.clientHeight;
        var scale = Math.min(width / 1920, height / 1080);
        // element.style.transform = "scale(" + scale + ")";
        element.style.transform = 'scale(' + width / 1920 + ',' + height / 1080 + ')';
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import url('@/styles/comstyle.css');

#container {
  width: 1920px;
  height: 1080px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -960px;
  margin-top: -540px;
  overflow: hidden;
  background-color: #fff;
}

#contApp {
  width: 100%;
  height: 100%;
  //display: flex;
  //align-items: center;
  //justify-content: center;
}
</style>
