<template>
  <div class="home-top-btn-style">
    <div class="switchbtn selectswitbtn">
      <span>"石榴籽"调解</span>
    </div>

    <div class="switchbtn" @click="switchbtnfun">
      <span>民意调查</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "menucoms.vue"
}
</script>

<style scoped>

</style>