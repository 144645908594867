<style lang="less" scoped>
.homeheader-bg-box {
  width: 100%;
  height: 80px;
  background-image: url("../../assets/components/head-home-img/title-bg-head.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // padding: 12px 16px;
  padding: 12px 0px;
  // margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;

  .leftrighticonstyle {
    width: 55px;
    height: 55px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .lefticon {
    background-image: url("@/assets/components/head-home-img/banhome-icon.png");
  }

  .righticon {
    background-image: url("@/assets/components/head-home-img/sets-icon.png");
  }

  .select-option-box {
    width: 520px;
    // justify-content: space-between;
    align-items: center;
    justify-content: flex-end;

    .selected-item-style:last-child {
      margin-right: 0px;
    }

    .selected-item-style {
      margin-right: 19px;
      background-image: url("@/assets/components/head-home-img/item-option.png");
      width: 160px;
      height: 40px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      position: relative;
      line-height: 40px;

      .drop-title-box {
        text-align: center;

        .drop-title-text {
          color: #2ba4f4;
          font-size: 20px;
          margin-right: 8px;
        }

        .drop-down-icon {
          width: 20px;
          height: 8px;
          background-image: url("@/assets/components/head-home-img/drop-down-icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      .custdorop {
        width: 100%;
        position: absolute;
        top: 40px;
        left: 0;
        padding: 12px;
        z-index: 9999;

        .drop-down-item-box {
          background-image: url("@/assets/components/head-home-img/drop-down-bg-activit.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 137px;
          height: 38px;
          font-size: 16px;
          color: #b4dcf7;
          text-align: center;
          line-height: 38px;
          margin-bottom: 10px;
        }

        .drop-down-item-box:hover {
          background-image: url("@/assets/components/head-home-img/drop-down-bg.png");
          color: #fff;
        }

        .drop-down-activt {
        }
      }
    }

    .selected-item-style:hover {
      background-image: url("@/assets/components/head-home-img/activt-item-option.png");
    }
  }
}

.newheader-title-text {
  font-size: 44px;
  color: #fff;
  font-weight: 800;
  letter-spacing: 8px;
  width: 708px;
  text-align: center;
}
</style>
<template>
  <div class="homeheader-bg-box arFlex">
    <div class="leftrighticonstyle lefticon" @click="clicknavgethome()"></div>
    <div class="select-option-box arFlex">
      <template v-for="(item, index) in RouteList">
        <div v-if="index < 3" :key="index" class="selected-item-style">
          <div
            class="drop-title-box"
            @mouseenter="dropdropshow(item.menuId)"
            @mouseleave="dropdropdropshow(item.menuId)"
          >
            <span class="drop-title-text">{{ item.menuName }}</span>
            <i class="drop-down-icon"></i>
          </div>
          <div
            v-show="menuid === item.menuId"
            class="custdorop"
            @mouseenter="dropdropshow(item.menuId)"
            @mouseleave="dropdropdropshow(item.menuId)"
          >
            <div
              v-for="(items, indexs) in item.children"
              :key="indexs"
              class="drop-down-item-box"
              @click="
                navtopushfun(
                  item.menuId,
                  items.url,
                  items.type,
                  items.status,
                  items
                )
              "
            >
              {{ items.name }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-if="userinfo" class="newheader-title-text">
      {{ userinfo.webTitle }}
    </div>
    <div class="select-option-box arFlex">
      <template v-for="(item, index) in RouteList">
        <div v-if="index >= 3" :key="index" class="selected-item-style">
          <div
            class="drop-title-box"
            @mouseenter="dropdropshow(item.menuId)"
            @mouseleave="dropdropdropshow(item.menuId)"
          >
            <span class="drop-title-text">{{ item.menuName }}</span>
            <i class="drop-down-icon"></i>
          </div>
          <div
            v-show="menuid === item.menuId"
            class="custdorop"
            @mouseenter="dropdropshow(item.menuId)"
            @mouseleave="dropdropdropshow(item.menuId)"
          >
            <div
              v-for="(items, indexs) in item.children"
              :key="indexs"
              class="drop-down-item-box"
              @click="
                navtopushfun(
                  item.menuId,
                  items.url,
                  items.type,
                  items.status,
                  items
                )
              "
            >
              {{ items.name }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div>
      <div style="text-align: right; display: flex; justify-content: flex-end">
        <!-- <span style="margin-right: 20px" class="arui-mrg-top">{{ bbb }}</span> -->
        <el-dropdown style="height: 44px" @command="handleCommand">
          <span class="el-dropdown-link leftrighticonstyle righticon"> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="1">跳至后台</el-dropdown-item>
            <el-dropdown-item :command="3">APP下载</el-dropdown-item>
            <el-dropdown-item :command="2">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import {
  appcheck,
  getrouters,
  getrouterslist,
  getviewtoken,
  saasinfo,
} from "@/request/api";
import router from "@/router";
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "newheader",
  // props: {
  //   RouteList: {
  //     type: Array,
  //     default: []
  //   }
  // },
  data() {
    return {
      userinfo: null,
      dropshow: false,
      menuid: null,
      RouteList: [],
      perxit: [
        {
          name: "跳至后台",
          url: "",
        },
        {
          name: "退出登录",
          url: "",
        },
      ],
    };
  },
  computed: {
    ...mapState(["head"]),
  },
  created() {
    saasinfo().then((res) => {
      localStorage.setItem("userinfo", JSON.stringify(res.data));
      this.userinfo = res.data;
    });
    getrouterslist()
      .then((res) => {
        res.data.map((item, index) => {
          item.isshow = false;
          item.menuIcon = process.env.VUE_APP_BASE_URL + item.menuIcon;
        });
        let _newArr = res.data;
        //莲花池
        if (JSON.parse(localStorage.userinfo).organId == 13) {
          _newArr = res.data.filter((item) => {
            return item.children.length > 0;
          });
        }
        this.RouteList = _newArr;
      })
      .catch((err) => {});
  },
  mounted() {},
  methods: {
    dropdropshow(e) {
      this.menuid = e;
    },
    dropdropdropshow(e) {
      this.menuid = null;
    },
    navtopushfun(menuId, url, type, status, items) {
      getrouters({ fmenu: menuId }).then((res) => {
        localStorage.setItem("routerslist", JSON.stringify(res.data));
        if (res.data.length === 0 || status === 2) {
          this.$message({
            message: "尽情期待",
            type: "warning",
          });
        } else {
          if (type === 2) {
            console.log(items);
            if (items.name == "平安视联") {
              getviewtoken().then((res) => {
                let resdata = res.data;
                let newurl = `${items.url}?productNo=${resdata.product}&tokenOther=${resdata.token}`;
                window.open(newurl, "_blank");
              });
            } else {
              window.open(url, "_blank");
            }
          } else {
            // let routeUrl = this.$router.resolve({
            //   path: `/${url}`,
            //   query: {menuId: menuId}
            // });
            // window.open(routeUrl.href, '_blank');
            this.$router.push({
              path: `/${url}`,
              query: { menuId: menuId },
            });
          }
        }
      });
    },
    clicknavgethome(e) {
      router.push({
        path: "/",
      });
    },
    handleCommand(e) {
      switch (e) {
        case 1:
          let token = localStorage.getItem("token");
          let workerid = localStorage.getItem("worker_id");
          let organid = localStorage.getItem("organ_id");

          window.open(
            `http://ht.jczsaas.com/?token=${token}&workerid=${workerid}&organid=${organid}`,
            "_blank"
          );

          // window.open(
          //     `http://localhost:80/?token=${token}&workerid=${workerid}&organid=${organid}`,
          //     "_blank"
          // );
          break;
        case 2:
          localStorage.clear();
          this.$router.replace({
            path: "/login",
          });
          break;
        case 3:
          axios
            .get("http://api.jczsaas.com/jczl/app/check", {
              params: {
                code: "JCZLZD",
              },
            })
            .then((res) => {
              let resdata = res.data.data;
              window.open(resdata.url);
            });
          break;
      }
    },
  },
};
</script>
