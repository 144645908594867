var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homeheader-bg-box arFlex"},[_c('div',{staticClass:"leftrighticonstyle lefticon",on:{"click":function($event){return _vm.clicknavgethome()}}}),_c('div',{staticClass:"select-option-box arFlex"},[_vm._l((_vm.RouteList),function(item,index){return [(index < 3)?_c('div',{key:index,staticClass:"selected-item-style"},[_c('div',{staticClass:"drop-title-box",on:{"mouseenter":function($event){return _vm.dropdropshow(item.menuId)},"mouseleave":function($event){return _vm.dropdropdropshow(item.menuId)}}},[_c('span',{staticClass:"drop-title-text"},[_vm._v(_vm._s(item.menuName))]),_c('i',{staticClass:"drop-down-icon"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuid === item.menuId),expression:"menuid === item.menuId"}],staticClass:"custdorop",on:{"mouseenter":function($event){return _vm.dropdropshow(item.menuId)},"mouseleave":function($event){return _vm.dropdropdropshow(item.menuId)}}},_vm._l((item.children),function(items,indexs){return _c('div',{key:indexs,staticClass:"drop-down-item-box",on:{"click":function($event){return _vm.navtopushfun(
                item.menuId,
                items.url,
                items.type,
                items.status,
                items
              )}}},[_vm._v(" "+_vm._s(items.name)+" ")])}),0)]):_vm._e()]})],2),(_vm.userinfo)?_c('div',{staticClass:"newheader-title-text"},[_vm._v(" "+_vm._s(_vm.userinfo.webTitle)+" ")]):_vm._e(),_c('div',{staticClass:"select-option-box arFlex"},[_vm._l((_vm.RouteList),function(item,index){return [(index >= 3)?_c('div',{key:index,staticClass:"selected-item-style"},[_c('div',{staticClass:"drop-title-box",on:{"mouseenter":function($event){return _vm.dropdropshow(item.menuId)},"mouseleave":function($event){return _vm.dropdropdropshow(item.menuId)}}},[_c('span',{staticClass:"drop-title-text"},[_vm._v(_vm._s(item.menuName))]),_c('i',{staticClass:"drop-down-icon"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuid === item.menuId),expression:"menuid === item.menuId"}],staticClass:"custdorop",on:{"mouseenter":function($event){return _vm.dropdropshow(item.menuId)},"mouseleave":function($event){return _vm.dropdropdropshow(item.menuId)}}},_vm._l((item.children),function(items,indexs){return _c('div',{key:indexs,staticClass:"drop-down-item-box",on:{"click":function($event){return _vm.navtopushfun(
                item.menuId,
                items.url,
                items.type,
                items.status,
                items
              )}}},[_vm._v(" "+_vm._s(items.name)+" ")])}),0)]):_vm._e()]})],2),_c('div',[_c('div',{staticStyle:{"text-align":"right","display":"flex","justify-content":"flex-end"}},[_c('el-dropdown',{staticStyle:{"height":"44px"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link leftrighticonstyle righticon"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":1}},[_vm._v("跳至后台")]),_c('el-dropdown-item',{attrs:{"command":3}},[_vm._v("APP下载")]),_c('el-dropdown-item',{attrs:{"command":2}},[_vm._v("退出登录")])],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }